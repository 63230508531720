

















































































































































































































































































































import Vue, { PropType } from "vue";
import {
  Business,
  CartMembership,
  CartProduct,
  CartService,
  CartServicePackage,
  Role,
} from "@/types";
import Quantity from "@/components/fragments/QuantityInput.vue";

import employeeStoreModule from "@/store/modules/employee";

import { createNamespacedHelpers } from "vuex";

type ProductType =
  | "product"
  | "service"
  | "service-package"
  | "membership"
  | "voucher";

const { mapActions: employeeActions, mapGetters: employeeGetters } =
  createNamespacedHelpers("EMPLOYEES_CHECKOUT");

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend<any, any, any, any>({
  name: "CartItem",
  components: { Quantity },
  props: {
    item: {
      type: Object as PropType<any>,
      required: true,
    },
    product_type: {
      type: String as PropType<ProductType>,
      required: true,
    },
  },
  data: () => ({
    apiUrl: VUE_APP_API_URL,
    menu: false,
  }),
  computed: {
    ...employeeGetters(["employeePage"]),
    role(): Role {
      return this.$store.getters.role;
    },
    products: function (): CartProduct[] {
      return this.$store.getters["cart/products"];
    },
    cart_services: function (): CartService[] {
      return this.$store.getters["cart/services"];
    },
    cart_memberships: function (): CartMembership[] {
      return this.$store.getters["cart/memberships"];
    },
    cartServicePackages: function (): CartServicePackage[] {
      return this.$store.getters["cart/servicePackages"];
    },
  },
  created() {
    this.getEmployees();
  },
  watch: {
    "item.appointmentTime": "getEmployees",
  },
  methods: {
    ...employeeActions(["getWithAvailability"]),
    updateProductQty(val: any) {
      const _item = this.products.filter((item) => item.sku === val)[0];
      if (_item) {
        this.$store.dispatch("cart/updateProductQty", {
          sku: _item.sku,
          qty: _item.quantity,
        });
      }
    },
    removeProductCart(sku: string) {
      this.$store.dispatch("cart/removeProductFromCart", sku);
    },
    removeServiceCart(id: string) {
      this.$store.dispatch("cart/removeServiceFromCart", id);
    },
    removeMembershipCart(id: string) {
      this.$store.dispatch("cart/removeMembershipFromCart", id);
    },
    removeVoucherCart(id: string) {
      console.log("rm v: ", id);
      this.$store.dispatch("cart/removeVoucherFromCart", id);
    },
    removeServicePackageCart(id: string) {
      this.$store.dispatch("cart/removeServicePackageFromCart", id);
    },
    updateServiceQty(id: string) {
      const _item = this.cart_services.filter(
        (_service) => _service.id === id
      )[0];
      if (_item) {
        this.$store.dispatch("cart/updateServiceQty", {
          _qty: _item.quantity,
          id: _item.id,
        });
      }
    },
    updateServiceUnitPrice(id: string) {
      const _item = this.cart_services.filter(
        (_service) => _service.id === id
      )[0];
      if (_item) {
        this.$store.dispatch("cart/updateServiceQty", {
          _price: _item.unitPrice,
          id: _item.id,
        });
      }
    },
    // updateServicePackageUnitPrice(id: string) {
    //   const sp = this.cartServicePackages.find((sp) => sp.id === id);
    //   if (sp) {
    //     this.$store.dispatch("cart/updateServicePackageQty", {
    //       _price: sp.unitPrice,
    //       id: sp.servicePackage,
    //     });
    //   }
    // },
    updateMembershipQty(id: string) {
      const _item = this.cart_memberships.filter(
        (_membership) => _membership.id === id
      )[0];
      if (_item) {
        this.$store.dispatch("cart/updateMembershipQty", {
          _qty: _item.quantity,
          id: _item.id,
        });
      }
    },
    getImageUrl(image: any) {
      if (image) {
        return `${this.apiUrl}/v1/file/${image.filename}`;
      }
      return "../../assets/placeholder.png";
    },
    getEmployees() {
      if (!this.item.appointmentDate || !this.item.appointmentTime) return;
      const bid = (this.role.business as Business)?._id;
      const date = `${this.item.appointmentDate} ${this.item.appointmentTime}`;
      const duration = this.item.service?.advancedPricing?.length
        ? this.item.service.advancedPricing[0].durationInMinutes
        : 30;
      let params = `?businessId=${bid}&durationInMinutes=${duration}&startDate=${date}`;
      if (this.item.services)
        params += `&serviceIds=${this.item.services.join("&serviceIds=")}`;
      else if (this.item.service)
        params += `&serviceIds=${this.item.service._id}`;
      this.getWithAvailability(params);
    },
    getRules(field: string) {
      return [(v: string) => !!v || `${field} is required`];
    },
    onlyDeposit() {
      //code
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("EMPLOYEES_CHECKOUT")) {
      this.$store.registerModule("EMPLOYEES_CHECKOUT", employeeStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("EMPLOYEES_CHECKOUT");
  },
});
