import { api } from "@/util/axios";
import { Module } from "vuex";

const VUE_APP_PAYMENTS_API_V1_URL = process.env.VUE_APP_PAYMENTS_API_V2_URL;

const actions = {
  set_vendor_gateways: "SET_VENDOR_GATEWAYS",
  set_vendor_transactions: "SET_VENDOR_TRANSACTIONS",
  set_vendor_stripe_transactions: "SET_STRIPE_TRANSACTIONS",
  set_vendor_stripe_payouts: "SET_STRIPE_PAYOUTS",
  set_gateway_settings: "SET_GATEWAY_SETTINGS",
  set_flw_transactions: "SET_FLW_TRANSACTIONS",
  set_flw_subscriptions: "SET_FLW_SUBSCRIPTIONS",
};

type VendorGatewayState = {
  vendorGateways: {
    gateways: { mpesaGateway: null; stripeGateway: null };
    isFlutterwaveSetup: boolean;
    isStripeSetup: boolean;
    isMpesaSetup: boolean;
  };
  transactions: any[];
  stripeTransactions: any[];
  stripePayouts: any[];
  settings: any;
  flwTransactions: {
    data: any[];
    total: number;
    current_page: number;
    total_pages: number;
  };
  flwSubscriptions: any[];
};

const paymentVendorGateway: Module<VendorGatewayState, unknown> = {
  namespaced: true,
  state: () => ({
    vendorGateways: {
      gateways: { mpesaGateway: null, stripeGateway: null },
      isFlutterwaveSetup: false,
      isStripeSetup: false,
      isMpesaSetup: false,
    },
    transactions: [],
    stripeTransactions: [],
    stripePayouts: [],
    settings: {
      mpesa_fee: 0,
      flutterwave_fee: 0,
      stripe_fee: 0,
      paypal_fee: 0,
    },
    flwTransactions: {
      data: [],
      total: 0,
      current_page: 1,
      total_pages: 1,
    },
    flwSubscriptions: [],
  }),
  getters: {
    gateways: (state) => state.vendorGateways,
    transactions: (state) => state.transactions,
    flwTransactions: (state) => state.flwTransactions,
    flwSubscriptions: (state) => state.flwSubscriptions,
    stripePayments: (state) => state.stripeTransactions,
    stripePayouts: (state) => state.stripePayouts,
    settings: (state) => state.settings,
  },
  mutations: {
    [actions.set_vendor_gateways]: (state, data) => {
      state.vendorGateways = data;
    },
    [actions.set_vendor_transactions]: (state, data) => {
      state.transactions = data;
    },
    [actions.set_flw_transactions]: (state, data) => {
      state.flwTransactions = data;
    },
    [actions.set_vendor_stripe_transactions]: (state, data) => {
      state.stripeTransactions = data;
    },
    [actions.set_vendor_stripe_payouts]: (state, data) => {
      state.stripePayouts = data;
    },
    [actions.set_gateway_settings]: (state, data) => {
      state.settings = data;
    },
    [actions.set_flw_subscriptions]: (state, data) => {
      state.flwSubscriptions = data;
    },
  },
  actions: {
    async bankTransfer(context, payload) {
      return await api
        .post(
          `/flutterwave/bank_transfer`,
          { ...payload },
          { baseURL: VUE_APP_PAYMENTS_API_V1_URL }
        )
        .then((response) => {
          const { status, message } = response.data;

          if (status == "error") {
            context.dispatch(
              "setToast",
              {
                title: "Transaction Failed!",
                type: "error",
                text: message,
              },
              { root: true }
            );
            return false;
          }
          context.dispatch(
            "setToast",
            {
              title: "Transaction Successful!",
              type: "success",
              text: message,
            },
            { root: true }
          );
          return true;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    createTransaction(context, payload) {
      return api.post(
        `payment/transactions`,
        { ...payload },
        { baseURL: VUE_APP_PAYMENTS_API_V1_URL }
      );
    },
    async stripeWithdraw(context, payload: any) {
      return await api.post(
        `stripe/withdraw`,
        { ...payload },
        {
          baseURL: VUE_APP_PAYMENTS_API_V1_URL,
        }
      );
    },
    async getStripeBalance(context, vendor_id: string) {
      return await api.get(`stripe/balance/${vendor_id}`, {
        baseURL: VUE_APP_PAYMENTS_API_V1_URL,
      });
    },
    fetchStripePayments(context, vendor_id: string) {
      api
        .get(`stripe/transactions/${vendor_id}`, {
          baseURL: VUE_APP_PAYMENTS_API_V1_URL,
        })
        .then((response) => {
          context.commit(
            actions.set_vendor_stripe_transactions,
            response.data.data
          );
        });
    },
    fetchStripePayouts(context, vendor_id: string) {
      api
        .get(`stripe/payouts/${vendor_id}`, {
          baseURL: VUE_APP_PAYMENTS_API_V1_URL,
        })
        .then((response) => {
          context.commit(actions.set_vendor_stripe_payouts, response.data.data);
        });
    },
    fetchPaymentTransactions(context, params) {
      api
        .get(`payment/transactions`, { baseURL: VUE_APP_PAYMENTS_API_V1_URL })
        .then((response) => {
          console.log(response);
          context.commit(actions.set_vendor_transactions, response.data);
        });
    },
    fetchPaymentGateways(context, vendor_id: string) {
      api.get(`/v2/money/gateway/${vendor_id}`).then((response) => {
        const { mpesaGateway, stripeGateway, paystackTransferRecipient } =
          response.data;
        context.commit(actions.set_vendor_gateways, {
          gateways: { mpesaGateway, stripeGateway, paystackTransferRecipient },
          isFlutterwaveSetup: false,
          isStripeSetup: !!stripeGateway,
          isMpesaSetup: !!mpesaGateway,
        });
      });
    },
    flutterwaveOnBoard(context, payload) {
      return api
        .post(
          `onboard/flutterwave`,
          { ...payload },
          { baseURL: VUE_APP_PAYMENTS_API_V1_URL }
        )
        .then((response) => {
          const { success, message } = response.data;
          if (!success) {
            context.dispatch(
              "setToast",
              {
                title: "Error!",
                type: "error",
                text: message,
              },
              { root: true }
            );
            return false;
          }

          context.dispatch(
            "setToast",
            {
              title: "Success!",
              type: "success",
              text: "Flutterwave OnBoarded successfully",
            },
            { root: true }
          );
          return true;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    flutterwaveUpdate(context, payload) {
      return api
        .put(
          `gateway/flw`,
          { ...payload },
          { baseURL: VUE_APP_PAYMENTS_API_V1_URL }
        )
        .then((response) => {
          const { success, message } = response.data;
          if (!success) {
            context.dispatch(
              "setToast",
              {
                title: "Error!",
                type: "error",
                text: message,
              },
              { root: true }
            );
            return false;
          }

          context.dispatch(
            "setToast",
            {
              title: "Success!",
              type: "success",
              text: "Flutterwave Updated successfully",
            },
            { root: true }
          );
          return true;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    stripeOnboard(context, payload: any) {
      return api
        .post(
          `/v2/money/stripe/account`,
          { ...payload }
          //{ baseURL: VUE_APP_PAYMENTS_API_V1_URL }
        )
        .then((response) => {
          const { url } = response.data;
          context.dispatch(
            "setToast",
            {
              title: "Success!",
              type: "success",
              text: "Success",
            },
            { root: true }
          );
          window.open(url, "_blank");
          return true;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async stripeUpdate(context, payload) {
      const { vendor_id, ...rest } = payload;
      console.log(payload);
      return await api.put(`/v2/money/stripe/account/${vendor_id}`, {
        ...rest,
      });
    },
    async mpesaOnboard(context, payload) {
      return await api
        .post(`/v2/money/mpesa/account`, { ...payload })
        .then((response) => {
          const { mpesaAccount } = response.data;
          context.dispatch(
            "setToast",
            {
              title: "Success!",
              type: "success",
              text: "Connected",
            },
            { root: true }
          );
          return mpesaAccount;
        })
        .catch((error) => {
          const message = error.response?.data?.error?.message;
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: message,
            },
            { root: true }
          );
        });
    },
    gatewaySettings(context, currency) {
      api
        .get(`settings/${currency}`, { baseURL: VUE_APP_PAYMENTS_API_V1_URL })
        .then((response) => {
          console.log(response);
          context.commit(actions.set_gateway_settings, response.data.settings);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    updateGatewaySettings(context, payload) {
      return true;
    },
    fetchFLWTransactions(context, params) {
      api
        .get(`payment/flw/transactions${params}`, {
          baseURL: VUE_APP_PAYMENTS_API_V1_URL,
        })
        .then((response) => {
          console.log(response);
          context.commit(actions.set_flw_transactions, response.data);
        });
    },
    fetchFLWSubscriptions(context, params) {
      api
        .get(`subscription`, { baseURL: VUE_APP_PAYMENTS_API_V1_URL })
        .then((response) => {
          console.log(response);
          context.commit(actions.set_flw_subscriptions, response.data);
        });
    },
  },
};

export default paymentVendorGateway;
