import axios from "axios";
import { PHONE_REGEX_KE } from "./constants";

const paymentEndpoint = process.env.VUE_APP_PAYMENTS_API_V1_URL,
  paymentEndpointV2 = process.env.VUE_APP_PAYMENTS_API_V2_URL;

const api = axios.create({
  baseURL: paymentEndpointV2,
});

api.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    console.log(error.response);
    return Promise.reject(error);
  }
);

export const cashPayment = (data: any) => {
  return api.post(`/cash/pay`, { ...data });
};

export const validateVoucherCode = (data: any) => {
  return api.post(`/vouchers/apply`, { ...data });
};

export const cardPayment = async (data: any) => {
  return await api.post(`/stripe/pay`, { ...data });
};

export const mpesaPayment = (data: any) => {
  return api.post(`/mpesa/pay`, { ...data });
};

export const mpesaPaymentStatus = (data: { payRequestRef: string }) => {
  return api.post(`/payment/mpesa/status`, {
    CheckoutRequestID: data.payRequestRef,
  });
};

export const getQrCode = (data: any) => {
  return api.post(
    `/qrcode/pay`,
    { ...data }
    //{ baseURL: paymentEndpoint }
  );
};

export const getBalance = (account: string) => {
  return api.get(`/payment/stripe/balance?account=${account}`, {
    baseURL: paymentEndpoint,
  });
};

export const getLoginLink = (account: string) => {
  return api.get(`/payment/stripe/express?account=${account}`, {
    baseURL: paymentEndpoint,
  });
};

export const validatePhone = (phoneNumber: string): boolean => {
  return PHONE_REGEX_KE.test(phoneNumber);
};
