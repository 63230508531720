import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { ClientMembership, Page } from "@/types";

const VUE_APP_PAYMENTS_API_V1_URL = process.env.VUE_APP_PAYMENTS_API_V2_URL;

type ClientMembershipState = {
  clientMembershipPage: Page<ClientMembership>;
};

const clientMembership: Module<ClientMembershipState, unknown> = {
  namespaced: true,
  state: () => ({
    clientMembershipPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getClientMembership: (state) => (clientMembershipId: string) =>
      state.clientMembershipPage.docs.find((c) => c._id === clientMembershipId),
    clientMembershipPage: (state) => state.clientMembershipPage,
  },
  mutations: {
    ADD_CLIENT_MEMBERSHIP: (state, _clientMembership) => {
      let idx = -1;
      state.clientMembershipPage.docs.map((r, i) => {
        if (r._id === _clientMembership._id) idx = i;
      });
      if (idx === -1) state.clientMembershipPage.docs.push(_clientMembership);
      else Vue.set(state.clientMembershipPage.docs, idx, _clientMembership);
    },
    SET_CLIENT_MEMBERSHIP_PAGE: (state, list) => {
      state.clientMembershipPage = list;
    },
    REMOVE_CLIENT_MEMBERSHIP(state, clientMembership) {
      let idx = -1;
      state.clientMembershipPage.docs.map((r, i) => {
        if (r._id === clientMembership._id) idx = i;
      });
      if (idx > -1) state.clientMembershipPage.docs.splice(idx, 1);
    },
  },
  actions: {
    async createMembershipSession(context, payload) {
      return await api
        .post("/v2/membership-session", { ...payload })
        .then((response) => {
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              //text: "Session created",
            },
            { root: true }
          );
          return response.data;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async verifyMembershipApply(context, payload) {
      return await api
        .post("/v1/membership-session/verify", { ...payload })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchClientSubscriptions(context, payload) {
      const { email, vendor_id, membership_id } = payload;
      return await api
        .get(`/v2/membership-session?membershipId=${membership_id}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchClientMembership(context, params = "") {
      api
        .get(`/v1/membership/client${params}`)
        .then((response) => {
          context.commit(
            "ADD_CLIENT_MEMBERSHIP",
            response.data.clientMembership
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchClientMembershipList(context, params = "") {
      api
        .get(`/v1/membership/client${params}`)
        .then((response) => {
          context.commit(
            "SET_CLIENT_MEMBERSHIP_PAGE",
            response.data.clientMembershipPage
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createClientMembership(context, payload) {
      return await api
        .post(`/v1/membership/client`, payload)
        .then((response) => {
          context.commit(
            "ADD_CLIENT_MEMBERSHIP",
            response.data.clientMembership
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "ClientMembership created",
            },
            { root: true }
          );
          return response.data.clientMembership;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    updateClientMembership(
      context,
      data: { id: string; clientMembership: ClientMembership }
    ) {
      api
        .put(`/v1/membership/client/${data.id}`, data.clientMembership)
        .then((response) => {
          context.commit(
            "ADD_CLIENT_MEMBERSHIP",
            response.data.clientMembership
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "ClientMembership updated",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteClientMembership(context, id) {
      api
        .delete(`/v1/membership/client${id}`)
        .then((response) => {
          context.commit(
            "REMOVE_CLIENT_MEMBERSHIP",
            response.data.clientMembership
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "ClientMembership deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default clientMembership;
