












import Vue from "vue";
export default Vue.extend<any, any, any, any>({
  name: "QuantityInput",
  props: {
    value: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  model: {
    event: "change",
  },
  data: () => ({
    count: 0,
  }),
  watch: {
    value(val) {
      this.count = val;
    },
  },
  created() {
    this.count = this.value;
  },
  methods: {
    add() {
      this.count += 1;
      this.$emit("change", this.count);
    },
    minus() {
      if (this.count > 1) {
        this.count -= 1;
        this.$emit("change", this.count);
      }
    },
  },
});
