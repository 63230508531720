import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Payment, Page } from "@/types";

const GATEWAY_API_URL = process.env.VUE_APP_PAYMENTS_API_V2_URL;

type PaymentState = {
  paymentPage: Page<Payment>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paymentAggregate: { [key: string]: any }[];
};

const payment: Module<PaymentState, unknown> = {
  namespaced: true,
  state: () => ({
    paymentPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
    paymentAggregate: [],
  }),
  getters: {
    getPayment: (state) => (paymentId: string) =>
      state.paymentPage.docs.find((c) => c._id === paymentId),
    paymentPage: (state) => state.paymentPage,
    paymentAggregate: (state) => state.paymentAggregate,
  },
  mutations: {
    FLUTTER_PAYMENT: (state, payment) => {
      console.log(payment);
    },
    ADD_PAYMENT: (state, _payment) => {
      let idx = -1;
      state.paymentPage.docs.map((a, i) => {
        if (a._id === _payment._id) idx = i;
      });
      if (idx === -1) state.paymentPage.docs.push(_payment);
      else Vue.set(state.paymentPage.docs, idx, _payment);
    },
    SET_PAYMENT_PAGE: (state, list) => {
      state.paymentPage = list;
    },
    REMOVE_PAYMENT(state, payment) {
      let idx = -1;
      state.paymentPage.docs.map((a, i) => {
        if (a._id === payment._id) idx = i;
      });
      if (idx > -1) state.paymentPage.docs.splice(idx, 1);
    },
    SET_PAYMENT_AGGREGATE(state, aggregate) {
      state.paymentAggregate = aggregate;
    },
  },
  actions: {
    fetchPayment(context, params = "") {
      api
        .get(`/v1/payment${params}`)
        .then((response) => {
          context.commit("ADD_PAYMENT", response.data.payment);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchPaymentList(context, params = "") {
      return await api
        .get(`/v1/payment${params}`)
        .then((response) => {
          context.commit("SET_PAYMENT_PAGE", response.data.paymentPage);
          return response.data.paymentPage;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchPaymentAggregate(context, params = "") {
      return await api
        .get(`/v1/payment${params}`)
        .then((response) => {
          context.commit(
            "SET_PAYMENT_AGGREGATE",
            response.data.paymentAggregate
          );
          return response.data.paymentAggregate;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createPayment(context, payload) {
      return await api
        .post(`/v1/payment`, payload)
        .then((response) => {
          context.commit("ADD_PAYMENT", response.data.payment);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Payment created",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },

    flutterWavePay(context, payload) {
      api
        .post(
          process.env.VUE_APP_PAYMENTS_API_V1_URL +
            `/payment/process/card-payment`,
          payload
        )
        .then((response) => {
          context.commit("FLUTTER_PAYMENT", response.data.data);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: response.data.data.message,
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    mPesaPay(context, payload) {
      api
        .post(
          process.env.VUE_APP_PAYMENTS_API_V1_URL +
            `/payment/process/flutterwave/mpesa`,
          payload
        )
        .then((response) => {
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: response.data.errorMessage,
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    updatePayment(context, data: { id: string; payment: Payment }) {
      api
        .put(`/v1/payment/${data.id}`, data.payment)
        .then((response) => {
          context.commit("ADD_PAYMENT", response.data.payment);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Payment updated",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deletePayment(context, id) {
      api
        .delete(`/v1/payment${id}`)
        .then((response) => {
          context.commit("REMOVE_PAYMENT", response.data.payment);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Payment deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async refundCard(context, payload) {
      return await api
        .post(`/stripe/refund`, { ...payload }, { baseURL: GATEWAY_API_URL })
        .then((response) => {
          if (response) {
            context.dispatch(
              "setToast",
              {
                title: "Success",
                type: "success",
                text: "Refund initiated",
              },
              { root: true }
            );
            return response.data;
          }
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async refundMpesa(context, payload) {
      return await api
        .post(`/mpesa/refund`, { ...payload }, { baseURL: GATEWAY_API_URL })
        .then((response) => {
          if (response) {
            context.dispatch(
              "setToast",
              {
                title: "Success",
                type: "success",
                text: "Refund initiated",
              },
              { root: true }
            );
            return response.data;
          }
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default payment;
